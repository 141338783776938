<template>
    <div>
        <a-row :gutter="8">
            <a-col :span="showWaterfactoryList ? 18 : 24">
                <a-card :title="title" :body-style="{padding: '5px 5px'}">
                    <div style="height: calc(100vh - 210px);">
                        <div style="height: 100%;">
                            <my-map  ref="myMap" v-if="loading" :adapter="adapter" :zoom="14" :center="convert([ 105.1552, 37.4678 ])" height="100%" projection="EPSG:3857">
                                <my-map-drawer width="300px" height="100px" placement="left" collapsed close-on-click-outside >
                                    <my-map-layers :layers="layers" :zIndex="1"></my-map-layers>
                                </my-map-drawer>
                                <!----><my-map-pointer placement="right-bottom"></my-map-pointer>
                                <my-map-zoom theme="light" placement="right-top"></my-map-zoom>
                                <my-map-geo :json="geo"  :zIndex="2"  @ready="geoReadyHandle"></my-map-geo>
                                <my-map-scale ></my-map-scale>
                                <my-map-overview :collapsed="true"></my-map-overview>
                                <my-map-marker :zIndex="2" v-if="qualityData.length > 0" :data="qualityData" multiple :src="markerPic" :scale="0.22">
                                </my-map-marker>
                                <div v-for="(item) in qualityData" :key="'a' + item.id">
                                <my-map-html :positioning="positioning" :position="item.coordinate">
                                    <div style="background-color: rgba(45,140,240,0);color: #22BCB9;font-weight:bold;margin-top: 15px;padding:5px;border-radius:8px;text-align:center;width:auto;">
                                        {{item.name}}
                                    </div>
                                </my-map-html>
                                </div>
                                <div v-for="(item) in qualityData" :key="'b' + item.id">
                                    <my-map-popup :position="item.coordinate"  v-if="item.status=='3'"  style="background-color: rgba(255,51,0,1);color: #fff;margin-bottom: 25px;border-radius:8px;text-align:left;width:auto;" :closable="false"  :showArrow="false">
                                        <div v-html="item.dataName+'<br/>'+item.time"></div>
                                    </my-map-popup>
                                    <my-map-popup :position="item.coordinate"  v-else-if="item.datastatus=='2'"  style="background-color: rgba(255,150,0,0.8);color: #fff;margin-bottom: 25px;border-radius:8px;text-align:left;width:auto;" :closable="false"  :showArrow="false">
                                        <div v-html="item.dataName+'<br/>'+item.time"></div>
                                    </my-map-popup>
                                    <my-map-scatter :coordinate="item.coordinate" v-if="item.datastatus=='2'||item.status=='3'" :ripple="ripple1" fill="red"></my-map-scatter>

                                    <my-map-popup :position="item.coordinate" v-else style="background-color: rgba(45,140,240,0.8);color: #fff;margin-bottom: 25px;border-radius:8px;text-align:left;width:auto;"  :closable="false" :showArrow="false">
                                        <div v-html="item.dataName+'<br/>'+item.time"></div>
                                    </my-map-popup>
                                </div>
                            </my-map>
                        </div>

                    </div>
                </a-card>
            </a-col>
            <a-col v-if="showWaterfactoryList" :span="6">
                <a-card title="区域列表">
                    <a-tree
                            style="height: calc(100vh - 248px);"
                            :show-line="true"
                            :tree-data="treeData"
                            :replace-fields="replaceFields"
                            :defaultExpandAll="true"
                            @select="onSelect"
                            @check="onCheck"
                            v-if="treeData.length > 0"
                    />
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
  import { fromLonLat } from "ol/proj";
  import Point from 'ol/geom/Point';
  import {Icon, Stroke, Style,Circle,Fill} from 'ol/style';
  import MyMapPointer from '@/components/map/MyMapPointers'
  import {MyMap,MyMapGeo,MyMapLayers,MyMapPlacement,MyMapScale,MyMapOverview,MyMapZoom,MyMapScatter,MyMapLink,MyMapPopup,MyMapMarker,MyMapHtml,MyMapDrawer} from '$ui/map'
  import Image from '@/assets/Image.png'
  import Terrain from '@/assets/terrain.png'
  import TDT from '$ui/map/sources/preview/TDT.png'
  import tianjin from '$ui/charts/geo/province/tianjin.json'
  import henan from '@/assets/henan5.json'
  import TileLayer from 'ol/layer/Tile'
  import XYZ from 'ol/source/XYZ'
  import pin from '$ui/map/sources/pin/red.png'
  import markerPic from '@/assets/marker.png'
  import parseStyle from '$ui/map/utils/style'
  import arrowPng from '$ui/map/sources/arrows/arrow.svg'
  import moment from 'moment'

  import { devicePage,deviceTypeAll,zhibiaoAll,monitorAll,groupAll,waterfactoryAll, findByWfid,getAreaParam,waterfactoryTree,findByWfid1 } from "@/api/monitor/index";

  const setStyle = function (bg = 'rgba(0,0,0,0)') {
    return parseStyle({
      stroke: {width: 10, color: bg}
    })
  }
  /**
   * 内部箭头样式
   * 用于每次渲染集合体的时候，OL 均会调用此函数以获得自定义样式
   * @param feature
   * @param res
   * @returns {undefined[]}
   */
  const arrowStyles =  ((feature, res)=> {
      const featureData = feature.getProperties()
      let geometry = feature.getGeometry().getLineString();
      const ls = featureData.ls
      /*let newData = this.leakData.filter(item => item.fromid==featureData.MidFrom&&item.toid==featureData.MidTo)
      let colorIndex=Math.floor(newData[0].leakPercent/5)
      let color=colorData[colorIndex>4?4:colorIndex]*/

      const value = Math.ceil(225 * parseInt(ls) / 5)
      let styles =  [];
      let color='green'
      if(ls=='0'){
        color=`#0000ff`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
        return styles;
      }else{
        color=`rgba(225, ${225 - value}, 0, 0.9)`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
      }
      const length = geometry.getLength();

      var radio = (50 * res) / length;
      var dradio = 1;//投影坐标系，如3857等，在EPSG:4326下可以设置dradio=10000
      for (var i = 0; i <= 1; i += radio) {
        var arrowLocation = geometry.getCoordinateAt(i);
        geometry.forEachSegment(function (end,start ) {
          if (start[0] == end[0] || start[1] == end[1]) return;
          var dx1 = end[0] - arrowLocation[0];
          var dy1 = end[1] - arrowLocation[1];
          var dx2 = arrowLocation[0] - start[0];
          var dy2 = arrowLocation[1] - start[1];
          if (dx1 != dx2 && dy1 != dy2) {
            if (Math.abs(dradio * dx1 * dy2 - dradio * dx2 * dy1) < 0.001) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);
                styles.push(new Style({
                  geometry: new Point(arrowLocation),
                  image: new Icon({
                    src: arrowPng,
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation + Math.PI ,
                    scale: .06
                  })
                }));
             }
        }
      });
    }
      return styles;
  })



  export default {
    name: "monitoring",
    components: {
      MyMap,
      MyMapGeo,
      MyMapPointer,
      MyMapLayers,
      MyMapPlacement,
      MyMapScale,
      MyMapOverview,
      MyMapZoom,
      MyMapScatter,
      MyMapLink,
      MyMapPopup,
      MyMapMarker,
      MyMapHtml,
      MyMapDrawer
    },
    data (){
      return {
        showWaterfactoryList: false,
        title: ' ',
        treeData:[],
        replaceFields: {
          children: 'children',
          title: 'name',
          key: 'id'
        },
        adapter:({LayerGroup})=>{
          let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
          return new LayerGroup({
            layers: settings.map(n =>
                this.createTdtLayer(n)
            )
          })
        },
        layers: [
          {
            title: '交通图',
            adapter: ({LayerGroup})=>{
              let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: TDT
          },
          {
            title: '影像图',
            adapter: ({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Image
          },
          {
            title: '地形图',
            adapter: ({LayerGroup})=>{
              let settings=['ter_w', 'cta_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Terrain
          }
        ],
        geo: henan,
        showArea: 'b',
        loading:true,
        ripple1: {
            color: 'red', // 默认与 fill 参数一致
            period: 2, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            type: 'fill' // 渲染类型 fill 或 stroke
        },
        ripple2: {
            period: 1, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            strokeColor: 'red',
            strokeWidth: 5, // 边框宽度
            type: 'stroke' // 渲染类型 fill 或 stroke
        },
        monitorList: [],
        markers: null,
        zuitaimarkers: null,
        pin,
        markerPic,
        pressureData:[],
        waterData:[],
        waterDataTotal:[],
        qualityData:[],
        dataAll: [],
        showData:'a',
        ifShow:true,
        ifShow2: false,
        positioning:'top-left'
      }
    },
    created(){
      let areaParam = getAreaParam()
      waterfactoryTree(areaParam).then(r=>{
        this.treeData = [r.data]
        // this.title = this.treeData[0].name
        this.title = '沙坡头区/宣和东台供水自动站'
      })
      // monitorAll().then(r=>{
      //   console.log(r)
      //   let monitorList = r.data
      //   let data1 = monitorList.filter(item => item.zutaiid ==null)
      //   let data2 = monitorList.filter(item => item.zutaiid !=null)
      //
      //   this.markers =this.createMarkers(data1)
      //   this.zuitaimarkers =this.createMarkers(data2)
      // })
      findByWfid1(1,4).then(r=>{
        let qualityData = r.data
        this.qualityData =this.createQualityData(qualityData)
        console.log('qualityData',this.qualityData)
      })
    },
    mounted(){

    },
    methods:{
      convert(obj){
        return fromLonLat(obj)
      },
      onSelect(selectedKeys, info) {
        console.log('selected', selectedKeys, info);
      },
      onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
      },
      createTdtLayer(t = 'vec_c') {
        return new TileLayer({
          source: new XYZ({
            url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=' + t + '&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
          })
        })
      },
      showAreaChange(val){
        console.log('-----------------------------'+val)
        this.loading=false
        if(val.target.value=='a'){
          this.$nextTick(() => {
            /*
            const map = this.$refs.myMap.map
            const layer = this.$refs.myMap.getLayer()
            if (layer) {
              map.removeLayer(layer)
            }
            if (this.adapter) {
              this.adapter.__MY_LAYER__ = true
              map.addLayer(this.adapter)
            }*/

            this.adapter=({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            }

            this.layers= [
              {
                title: '影像图',
                adapter: ({LayerGroup})=>{
                  let settings=['img_w', 'cia_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: ({LayerGroup})=>{
                  let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: ({LayerGroup})=>{
                  let settings=['ter_w', 'cta_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }else{
          this.$nextTick(() => {
            this.adapter={
              type: 'XYZ',
              // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
              url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
            }

            this.layers= [
              {
                title: '影像图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'http://webst0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}'
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }
      },
      showDataChange(val){
        this.dataAll=[]
        const value = val.target.value
        if(value =='a'){
          //this.ifShow=true
          //this.ifShow2 = false
          this.$nextTick(() => {
            this.dataAll = this.pressureData
          })
        }else if(value == 'b'){
            //this.ifShow=false
            //this.ifShow2 = true
          this.$nextTick(() => {
            this.dataAll = this.waterData
          })
        }else{
          this.$nextTick(() => {
            this.dataAll = this.waterDataTotal
          })
        }
      },
      createMarkers(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          if(data[i].id<=5){
            markers.push({
              coordinate: this.convert([data[i].lon,data[i].lat]),
              dataName: data[i].name,
            })
          }
        }
        return markers
      },
      createQualityData(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
            markers.push({
            coordinate: this.convert([data[i].lon,data[i].lat]),
            name:data[i].name,
            dataName:  '浊度：'+data[i].zd+'<br/>'+'ph值：'+data[i].ph,
            time: '更新时间：'+moment(data[i].time).format('YYYY-MM-DD HH:mm') ,
            id: data[i].id,
            datastatus:data[i].datastatus,
            status:data[i].status
            })
        }
        return markers
      },
      createPerssureData(data,zhibiao) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          if(data[i].id<=5){
          let deviceDetails=data[i].deviceDetails
            if(deviceDetails.length>0){
              markers.push({
                coordinate: this.convert([data[i].lon,data[i].lat]),
                dataName: deviceDetails.map(item => item.name+'水压：'+item.pressure.toFixed(3)+'Mpa   '+(item.warnning?'<b>['+item.warnning.descr+']</b>':'')).join("<br/>") ,
                time: '更新时间：'+moment(deviceDetails[0].time).format('YYYY-MM-DD HH:mm') ,
                id: i,
                datastatus:deviceDetails[0].datastatus,
                status:deviceDetails[0].status
              })
            }
          }
        }
        return markers
      },
      geoReadyHandle(geo) {
        const features = geo.getFeatures()
        features.forEach((feature) => {
          const featureData = feature.getProperties()
          const ls = featureData.ls
          if(ls){
              feature.setStyle(arrowStyles)

            /*
            if(ls=='0'){
              const styleObj = setStyle(`#ff9900`)
              feature.setStyle(styleObj)
            }else{
              const value = Math.ceil(225 * parseInt(ls) / 5)
              const styleObj = setStyle(`rgba(225, ${225 - value}, 0, 0.9)`)
              feature.setStyle(styleObj)
            }*/
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
